"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OWEventLogsToJSON = exports.OWEventLogsFromJSONTyped = exports.OWEventLogsFromJSON = void 0;
function OWEventLogsFromJSON(json) {
    return OWEventLogsFromJSONTyped(json, false);
}
exports.OWEventLogsFromJSON = OWEventLogsFromJSON;
function OWEventLogsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'user_id': json['user_id'],
        'game_id': json['game_id'],
        'reward_id': json['reward_id'],
        'event': json['event'],
        'is_used': json['is_used'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'id': json['id'],
    };
}
exports.OWEventLogsFromJSONTyped = OWEventLogsFromJSONTyped;
function OWEventLogsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'user_id': value.user_id,
        'game_id': value.game_id,
        'reward_id': value.reward_id,
        'event': value.event,
        'is_used': value.is_used,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'id': value.id,
    };
}
exports.OWEventLogsToJSON = OWEventLogsToJSON;
